var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.onboardData.length > 0)?_c('b-row',{staticClass:"py-2 align-items-middle"},[_c('b-col',{staticClass:"on-boarding",attrs:{"lg":"4"}},[_c('b-card',[_c('b-card-body',[_c('b-card-title',{staticClass:"font-weight-bold mb-3",attrs:{"title-tag":"h2"}},[_c('h1',[_vm._v("Let's Start")])]),_c('ul',{staticClass:"todo"},[_vm._l((_vm.onboardData.filter(
                function (item) { return item.onboard_completed; }
              )),function(onboard,index){return _c('li',{key:onboard.title,staticClass:"is-complete"},[_c('h4',[_vm._v(_vm._s(onboard.title))])])}),_vm._l((_vm.onboardData.filter(
                function (item) { return !item.onboard_completed; }
              )),function(onboard,index){return _c('li',{key:onboard.title,staticClass:"is-current"},[_c('h4',[_vm._v(_vm._s(onboard.title))]),(index + 1 == 1)?_c('div',{staticClass:"content"},[_c('p',[_vm._v(_vm._s(onboard.description))]),_c('b-button',{attrs:{"variant":"gradient-primary"},on:{"click":function($event){return _vm.addOnboard(
                      onboard.name,
                      onboard.title,
                      onboard.description
                    )}}},[_vm._v(" "+_vm._s(onboard.title)+" ")])],1):_vm._e()])})],2)],1)],1)],1),_c('b-col',{attrs:{"lg":"8"}},[(_vm.onboardName == 'skills')?_c('b-card',{staticClass:"onboard-form"},[_c('b-card-body',[_c('Skill',{attrs:{"title":_vm.onboardTitle,"description":_vm.onboardDescription,"onboardAPi":_vm.onboard,"nextOnboard":_vm.addOnboard,"nextName":_vm.onboardData.filter(function (item) { return !item.onboard_completed; }).length > 0
                ? _vm.onboardData.filter(function (item) { return !item.onboard_completed; })[0]
                    .name
                : null,"nextTitle":_vm.onboardData.filter(function (item) { return !item.onboard_completed; }).length > 0
                ? _vm.onboardData.filter(function (item) { return !item.onboard_completed; })[0]
                    .title
                : null}})],1)],1):(_vm.onboardName == 'info')?_c('b-card',{staticClass:"onboard-form"},[_c('b-card-body',[_c('BasicInfo',{attrs:{"title":_vm.onboardTitle,"description":_vm.onboardDescription,"onboardAPi":_vm.onboard,"nextOnboard":_vm.addOnboard,"nextName":_vm.onboardData.filter(function (item) { return !item.onboard_completed; })[0].name,"nextTitle":_vm.onboardData.filter(function (item) { return !item.onboard_completed; })[0].title}})],1)],1):(_vm.onboardName == 'experiences')?_c('b-card',{staticClass:"onboard-form"},[_c('b-card-body',[_c('Experience',{attrs:{"title":_vm.onboardTitle,"description":_vm.onboardDescription,"onboardAPi":_vm.onboard,"nextOnboard":_vm.addOnboard,"nextName":_vm.onboardData.filter(function (item) { return !item.onboard_completed; })[0].name,"nextTitle":_vm.onboardData.filter(function (item) { return !item.onboard_completed; })[0].title}})],1)],1):(_vm.onboardName == 'preference')?_c('b-card',{staticClass:"onboard-form"},[_c('b-card-body',[_c('Preference',{attrs:{"title":_vm.onboardTitle,"description":_vm.onboardDescription,"onboardAPi":_vm.onboard,"nextOnboard":_vm.addOnboard,"nextName":_vm.onboardData.filter(function (item) { return !item.onboard_completed; })[0].name,"nextTitle":_vm.onboardData.filter(function (item) { return !item.onboard_completed; })[0].title}})],1)],1):(_vm.onboardName == 'candidateUrls')?_c('b-card',{staticClass:"onboard-form"},[_c('b-card-body',[_c('AddUrl',{attrs:{"title":_vm.onboardTitle,"description":_vm.onboardDescription,"nextOnboard":_vm.addOnboard,"nextName":_vm.onboardData.filter(function (item) { return !item.onboard_completed; }).length > 0
                ? _vm.onboardData.filter(function (item) { return !item.onboard_completed; })[0]
                    .name
                : null,"nextTitle":_vm.onboardData.filter(function (item) { return !item.onboard_completed; }).length > 0
                ? _vm.onboardData.filter(function (item) { return !item.onboard_completed; })[0]
                    .title
                : null}})],1)],1):(_vm.onboardName == 'cvs')?_c('b-card',{staticClass:"onboard-form"},[_c('b-card-body',[_c('Cv',{attrs:{"title":_vm.onboardTitle,"description":_vm.onboardDescription,"nextOnboard":_vm.addOnboard,"nextName":_vm.onboardData.filter(function (item) { return !item.onboard_completed; }).length > 0
                ? _vm.onboardData.filter(function (item) { return !item.onboard_completed; })[0]
                    .name
                : null,"nextTitle":_vm.onboardData.filter(function (item) { return !item.onboard_completed; }).length > 0
                ? _vm.onboardData.filter(function (item) { return !item.onboard_completed; })[0]
                    .title
                : null}})],1)],1):(_vm.onboardName == 'educations')?_c('b-card',{staticClass:"onboard-form"},[_c('b-card-body',[_c('Education',{attrs:{"title":_vm.onboardTitle,"description":_vm.onboardDescription,"nextOnboard":_vm.addOnboard,"nextName":_vm.onboardData.filter(function (item) { return !item.onboard_completed; }).length > 0
                ? _vm.onboardData.filter(function (item) { return !item.onboard_completed; })[0]
                    .name
                : null,"nextTitle":_vm.onboardData.filter(function (item) { return !item.onboard_completed; }).length > 0
                ? _vm.onboardData.filter(function (item) { return !item.onboard_completed; })[0]
                    .title
                : null}})],1)],1):_c('div',{staticClass:"w-100 d-lg-flex align-items-center justify-content-center px-2"},[_c('b-img',{attrs:{"fluid":"","src":_vm.imgUrl,"alt":"Login V2"}})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }