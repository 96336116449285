<template>
  <b-form @submit.prevent="saveUrl" class="w-100">
    <h2 class="pb-2" v-if="title">{{ title }}</h2>
    <p class="pb-2" v-if="description">{{ description }}</p>
    <b-button variant="primary" @click="addUrl()" class="mb-2 ml-auto">
      <feather-icon icon="PlusCircleIcon" size="18" />
    </b-button>
    <b-row
      v-for="(url, index) in form.urls"
      :key="index"
      class="align-items-end w-100 mb-1"
    >
      <b-col cols="12" lg="3" style="min-height: 75px">
        <label>Select Media </label>
        <Select v-model="url.url_info_id" filterable>
          <template #prefix>
            <Avatar
              v-if="url.url_info_id"
              :src="icons[url.url_info_id]"
              size="small"
            />
            <Avatar
              v-else
              src="https://dev-file.iviewui.com/userinfoPDvn9gKWYihR24SpgC319vXY8qniCqj4/avatar"
              size="small"
            />
          </template>
          <Option
            v-if="filteredUrlOptions(index).length"
            v-for="item in filteredUrlOptions(index)"
            :value="item.id"
            :key="item.id"
          >
            {{ item.name }}
          </Option>
        </Select>
        <small
          v-if="form.errors.has('urls.' + index + '.url_info_id')"
          class="text-danger"
          >This field is required
        </small>
      </b-col>

      <b-col cols="12" lg="8" style="min-height: 75px">
        <label>Link</label>
        <Input
          v-model="url.url"
          placeholder="https://example.com"
          style="width: 100%"
          type="text"
        />
        <small
          v-if="form.errors.has('urls.' + index + '.url')"
          class="text-danger"
          >
          Give  Valid Url
        </small>
      </b-col>
      <b-col cols="12" lg="1" style="min-height: 57px">
        <b-button
          variant="danger"
          @click="removeUrl(index)"
          class="w-100"
          :disabled="form.urls.length == 1"
        >
          <feather-icon icon="XCircleIcon" size="18" />
        </b-button>
      </b-col>
    </b-row>

    <div>
      <b-button variant="primary" type="submit" :disabled="form.busy">
        Save
      </b-button>
      <b-button
        variant="primary"
        class="ml-1"
        v-if="allUrlLength > 0 && nextName"
        @click="nextOnboard(nextName, nextTitle)"
      >
        Next
      </b-button>
    </div>
  </b-form>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BForm,
  BButton,
} from "bootstrap-vue";
import { HasError, AlertError } from "vform/src/components/bootstrap5";

export default {
  props: ["title", "nextOnboard", "nextTitle", "nextName", "description"],
  data() {
    return {
      form: new Form({
        urls: [
          {
            url_info_id: null,
            url: null,
          },
        ],
      }),
      allUrlLength: null,
      urlsData: [],
      icons: [],
    };
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    HasError,
    AlertError,
  },
  computed: {
    filteredUrlOptions() {
      return (index) => {
        const selectedUrlIds = this.form.urls
          .slice(0, index)
          .map((url) => url.url_info_id);
        return this.urlsData.filter((url) => !selectedUrlIds.includes(url.id));
      };
     },
  },
  mounted() {
    this.getUrls();
    this.getCandidateUrls();
  },
  methods: {
    getUrls() {
      axios
        .get("/candidate/get/urls")
        .then((res) => {
          this.urlsData = res.data.data;
          this.icons = res.data.data.reduce(
            (e, v) => ({
              ...e,
              [v.id]: v.icon,
            }),
            {}
          );
        })
        .catch((e) => {
          this.e(e.response.data.error);
        });
    },

    saveUrl() {
      this.form
        .post("/candidate/urls")
        .then((res) => {
          this.getCandidateUrls();
          this.s(res.data.message);
        })
        .catch((e) => {
          this.e(e.response.data.message);
        });
    },
    getCandidateUrls() {
      axios
        .get("/candidate/urls")
        .then((res) => {
          let urls = res.data.urls;
          let length = urls.length;
          if (length > 0) {
            this.$store.dispatch("onboardData");
            this.allUrlLength = length;
            this.form.urls = [];
            urls.forEach((data) => {
              this.form.urls.push({
                url_info_id: data.url_info_id,
                url: data.url,
              });
            });
          }
        })
        .catch((e) => {
          this.e(e.response.data.error);
        });
    },
    addUrl() {
      this.form.urls.push({
        url_info_id: null,
        url: null,
      });
    },
    removeUrl(index) {
      this.form.urls.splice(index, 1);
    },
  },
};
</script>
